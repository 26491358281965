import { createContext, useContext } from "react";
import { NextRouter, useRouter } from "next/router";
import useSWR from "swr";
import useAuthFetch from "@hooks/use-auth-fetch";
import { flattenQueryStringKey } from "@utils/query-string";
import { Currency } from "@type-defs/currency";

export type Vendor = {
  logo?: string;
  name?: string;
  vendorId: string;
  currency?: Currency;
  siteUrl?: string;
  country?: string;
};

type VendorListResponse = {
  data: Vendor[];
};
const MULTI_VENDOR_KEY = "multi"; // iit will be shown in the URL, so it should be a readable key
type ProviderProps = {
  children: React.ReactNode;
};

type VendorContentType = {
  multiVendorKey: string;
  vendors?: string[];
  vendorsData?: Vendor[];
  selectedVendor?: string;
  getVendorSettings: (vendor?: string) => Vendor | undefined;
  getVendorStyles: () => any;
};

const VendorContentContext = createContext<VendorContentType>({
  multiVendorKey: MULTI_VENDOR_KEY,
  getVendorSettings: () => undefined,
  getVendorStyles: () => undefined,
});

const getVendor = (router: NextRouter) => {
  const vendor = flattenQueryStringKey(router.query, "vendor");

  return vendor;
};

export const VendorContentProvider = ({ children }: ProviderProps) => {
  const router = useRouter();
  const { fetcher, isReady } = useAuthFetch();

  const { data: vendorListData, error: vendorListError } =
    useSWR<VendorListResponse>(
      isReady &&
        `${process.env.NEXT_PUBLIC_STATS_API_V2}/permissionsVendorsList`,
      fetcher,
    );
  if (vendorListError) {
    console.error("Error fetching vendor list:", vendorListError);
    // Consider showing a user-friendly error message or retry mechanism
  }
  const { data: vendorStyles } = useSWR<VendorListResponse>(
    isReady &&
      getVendor(router) &&
      getVendor(router) !== MULTI_VENDOR_KEY &&
      `${process.env.NEXT_PUBLIC_STATS_API}/profile/vendors/${getVendor(router)}/style`,
    fetcher,
  );
  let vendorList = vendorListData?.data;

  let vendors = vendorList?.map((v) => v.vendorId);

  // this is getting a bit hacky...
  if (vendors?.includes("*")) {
    if (vendorList) {
      vendors = vendorList.map((v) => v.vendorId);
    }
  }

  // sort vendors alphabetically
  vendors?.sort((a, b) => a.localeCompare(b));
  vendorList?.sort((a, b) => a.vendorId.localeCompare(b.vendorId));
  // add multi vendor key if there are more than one vendor
  if (vendors && vendors?.length > 1) {
    if (!vendors.includes(MULTI_VENDOR_KEY)) {
      vendors = [MULTI_VENDOR_KEY, ...vendors];
    }
    if (
      vendorList &&
      !vendorList.find((v) => v.vendorId === MULTI_VENDOR_KEY)
    ) {
      vendorList = [
        {
          vendorId: MULTI_VENDOR_KEY,
          name: "Vendors overview",
        },
        ...vendorList,
      ];
    }
  }
  // return like this for backwards compatibility
  let selectedVendor = getVendor(router);

  const getVendorSettings = (vendor?: string) => {
    if (!vendor) return undefined;
    if (!vendorList) {
      console.warn("Vendor list is still loading");
      return undefined;
    }
    return vendorList.find((v) => v.vendorId === vendor);
  };

  const getVendorStyles = () => {
    if (!vendorList) return {};
    return vendorStyles || {};
  };

  return (
    <VendorContentContext.Provider
      value={{
        multiVendorKey: MULTI_VENDOR_KEY,
        selectedVendor,
        vendors,
        vendorsData: vendorList,
        getVendorSettings,
        getVendorStyles,
      }}
    >
      {children}
    </VendorContentContext.Provider>
  );
};

export const useVendorContext = () => useContext(VendorContentContext);
