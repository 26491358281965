import { uniq } from "lodash";

export const WANTED_SCOPES = [
  "stats:views:read",
  "stats:reports:manage",
  "discounts:checkout:read",
  "discounts:checkout:write",
  "discounts:onetime:read",
  "discounts:onetime:write",
  "external-origins:external-purchases:read",
  "external-origins:external-purchases:write",
  "wallet:user:read",
  "wallet:user:write",
  "profile:user-vendors:write",
  "profile:user-vendors:read",
  "vault:impersonate",
  "vault:entitlement:manage",
  "vault:entitlement-share:manage",
  "payments:contract:admin",
  "profile:user:read",
  "discounts:stats:read",
  "fulfillment:read",
  "fulfillment:write",
  "fulfillment:sync",
  "checkout3migration:showOldStats",
  "podcasts:read",
  "podcasts:write",
  "paywall:read",
  "paywall:write",
  "profile:vendor-assets:write",
  "profile:vendors:read",
  "profile:vendor:manage-self",
  "payments:bankgiro:upload",
];

export type Permission = {
  vendorId: string;
  scopes: string[];
  resources: string[];
};

export type PermissionsResponse = {
  userId: string;
  bundledAt: string;
  permissions: Permission[];
};

export const getScopesToRequest = (
  scopesWeWant: string[],
  scopesWeHave: string[],
) => {
  return scopesWeWant.filter((scope) => scopesWeHave.includes(scope));
};

export const getScopesWeHaveForVendor = (
  permissions: Permission[],
  vendorId: string,
) => {
  const wildcardPermission = permissions.find(
    (permission) => permission.vendorId === "*",
  );

  const vendorPermissions = permissions.find(
    (permission) => permission.vendorId === vendorId,
  );

  const vendorAndWildcardScopes = uniq([
    ...(vendorPermissions ? vendorPermissions.scopes : []),
    ...(wildcardPermission ? wildcardPermission.scopes : []),
  ]);

  return vendorAndWildcardScopes;
};
