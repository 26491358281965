import { createContext, useContext, useState, useEffect } from "react";
import { useAuthTokenContext } from "@contexts/auth-token-context";
import {
  getScopesToRequest,
  PermissionsResponse,
  getScopesWeHaveForVendor,
  WANTED_SCOPES,
} from "@utils/sesamy-token";
import useSWR from "swr";
import useAuthFetch from "@hooks/use-auth-fetch";
import { useVendorContext } from "@contexts/vendor-context";

type ProviderProps = {
  children: React.ReactNode;
};

type SesamyAuthTokenContextType = {
  accessToken?: string;
  // temp for debugging
  invalidateAccessToken: () => void;
  getNewSesamyToken: () => Promise<void>;
  scopesWeHaveForVendor?: string[];
};

type SesamyTokenResponse = {
  access_token: string;
  token_type: string;
  expires_in: number;
};

export const SesamyAuthTokenContext = createContext<SesamyAuthTokenContextType>(
  {
    accessToken: undefined,
    invalidateAccessToken: () => {},
    getNewSesamyToken: async () => Promise.resolve(),
    scopesWeHaveForVendor: [],
  },
);

const sesamyTokenFetcher = async (
  auth0AccessToken: string,
  scopesWeHaveForVendor: string[],
  vendorId: string,
) => {
  const scopes = getScopesToRequest(WANTED_SCOPES, scopesWeHaveForVendor);
  const scope = scopes.join(" ");

  const requestData = {
    grant_type: "access_token",
    scope,
    audience: "sesamy.com",
    vendor_id: vendorId,
    access_token: auth0AccessToken,
  };

  if (!process.env.NEXT_PUBLIC_SESAMY_TOKEN_URL)
    throw new Error("No NEXT_PUBLIC_SESAMY_TOKEN_URL");

  const sesamyTokenResponse = await fetch(
    process.env.NEXT_PUBLIC_SESAMY_TOKEN_URL,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    },
  );

  const data: SesamyTokenResponse = await sesamyTokenResponse.json();

  return data;
};

export const SesamyAuthTokenProvider = ({ children }: ProviderProps) => {
  const [tokenResponse, setTokenResponse] = useState<SesamyTokenResponse>();
  const { authToken: auth0AccessToken } = useAuthTokenContext();
  const { fetcher, isReady } = useAuthFetch();
  const { selectedVendor } = useVendorContext();

  const { data: permissions } = useSWR<PermissionsResponse>(
    isReady && `${process.env.NEXT_PUBLIC_STATS_API}/profile/me/permissions/`, // this is also fetched inside vendor context... swr can handle this
    fetcher,
  );

  //  TODO - useSWR here...
  useEffect(() => {
    async function fetchSesamyToken(accessToken: string) {
      try {
        const res = await sesamyTokenFetcher(
          accessToken,
          scopesWeHaveForVendor,
          selectedVendor!,
        );
        setTokenResponse(res);
      } catch (e) {
        // TODO
        // - what here?
      }
    }

    if (!selectedVendor) return;

    if (!permissions) return;

    const allVendorPermissions = permissions?.permissions;

    if (!allVendorPermissions) return;

    const scopesWeHaveForVendor = getScopesWeHaveForVendor(
      allVendorPermissions,
      // TODO - lots of type inference issues here
      selectedVendor!,
    );

    if (auth0AccessToken) {
      fetchSesamyToken(auth0AccessToken);
    }
  }, [
    auth0AccessToken,
    permissions,
    // TODO - test that this works...
    selectedVendor,
  ]);

  const invalidateAccessToken = () => {
    setTokenResponse({
      access_token: "invalid-token",
      token_type: tokenResponse?.token_type || "",
      expires_in: tokenResponse?.expires_in || 0,
    });
  };

  const getNewSesamyToken = async () => {
    if (!auth0AccessToken) return;

    // this is not tested... should always exist here if we are getting a new one
    if (!scopesWeHaveForVendor) return;

    const res = await sesamyTokenFetcher(
      // TODO - what if this gives us a 401?
      auth0AccessToken,
      scopesWeHaveForVendor,
      selectedVendor!,
    );
    setTokenResponse(res);
  };

  const allVendorPermissions = permissions?.permissions;
  const scopesWeHaveForVendor =
    allVendorPermissions &&
    getScopesWeHaveForVendor(allVendorPermissions, selectedVendor!);

  return (
    <SesamyAuthTokenContext.Provider
      value={{
        accessToken: tokenResponse?.access_token,
        invalidateAccessToken,
        getNewSesamyToken,
        scopesWeHaveForVendor,
      }}
    >
      {children}
    </SesamyAuthTokenContext.Provider>
  );
};

export const useSesamyAuthTokenContext = () => {
  const context = useContext(SesamyAuthTokenContext);

  const {
    accessToken,
    invalidateAccessToken,
    getNewSesamyToken,
    scopesWeHaveForVendor,
  } = context;

  return {
    accessToken,
    // backwards compatibility - this was a mistake having similarly named!
    authToken: accessToken,
    invalidateAccessToken,
    getNewSesamyToken,
    scopesWeHaveForVendor,
  };
};
